import { FC, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import { Box, Card, makeStyles } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { GET_PRODUCER_QUALITY_GROUP_PRODUCTS } from 'graphql/queries';
import IProduct from 'models/product';
import { IFoodlaCategoryOption } from 'models/category';
import ReviewTable from './ReviewTable';
import { COLUMN_DETAILS } from './constants';
import { matchColumnCategories } from '../utils/columnValidator';

// Styles
const useStyles = makeStyles(theme => ({
  link: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

interface QualityGroupReviewProps {
  loadingCategory?: boolean;
  rootCategory?: IFoodlaCategoryOption;
}

const QualityGroupReview: FC<QualityGroupReviewProps> = ({ loadingCategory, rootCategory }) => {
  const history = useHistory();
  const { producerId, qualityGroupId } = useParams<{ producerId?: string; qualityGroupId?: string }>();

  const classes = useStyles();

  const { loading, data } = useQuery(GET_PRODUCER_QUALITY_GROUP_PRODUCTS, {
    variables: { producerId, qualityGroupId },
  });

  const [products, setProducts] = useState<IProduct[]>([]);

  const columns = useMemo(() => {
    if (!rootCategory) return [];

    return COLUMN_DETAILS.filter(column => {
      let matchedCondition = false;

      products.forEach(product => {
        if (column.conditionFunc) {
          if (column.conditionFunc?.(product, rootCategory)) {
            matchedCondition = true;
          }
          // If conditionFunc defined, ignore all other conditions
          return;
        }

        if (matchColumnCategories({ product, column, rootCategory })) {
          matchedCondition = true;
        }
      });

      return matchedCondition;
    });
  }, [rootCategory, products]);

  useEffect(() => {
    if (loading || loadingCategory) return;
    setProducts(data?.producerQualityGroupProducts || []);
  }, [data?.producerQualityGroupProducts, loading, loadingCategory]);

  return (
    <div>
      <Box display="flex" alignItems="center" gridGap={4} mb={3}>
        <Box className={classes.link} onClick={() => history.push('/superuser/bulk-review')}>
          Butiker och Leverantörer
        </Box>
        <ArrowForwardIosIcon style={{ fontSize: 14 }} />
        <Box className={classes.link} onClick={() => history.push(`/superuser/bulk-review/${producerId}`)}>
          Kategorier
        </Box>
        <ArrowForwardIosIcon style={{ fontSize: 14 }} />
        <Box fontWeight={700}>Produkter</Box>
      </Box>

      <Card>
        <ReviewTable
          loading={loading || loadingCategory}
          products={products}
          setProducts={setProducts}
          columns={columns}
          rootCategory={rootCategory}
        />
      </Card>
    </div>
  );
};

export default QualityGroupReview;
