import gql from 'graphql-tag';

export const PRODUCT_SEARCH = gql`
  query product($string: String!) {
    productByEANorSearchByText(string: $string) {
      data {
        id
        title
        EAN
        EAN2
        creator
        category
        foodlaCategory {
          id
          name
          coopCategoryId
          icaCategory
          coopCategory
        }
        pictures {
          small {
            url
          }
        }
        producer {
          id
          name
        }
      }
    }
  }
`;

export const PRODUCT_SEARCH_WITH_PRODUCER = gql`
    query product($string: String!) {
        productByEANorSearchByTextOrByProducer(string: $string) {
          data {
            id
            title
            EAN
            EAN2
            creator
            category
            foodlaCategory {
              id
              name
              coopCategoryId
              icaCategory
              coopCategory
            }
            pictures {
              small {
                url
              }
            }
            producer {
              id
              name
            }
          }
        }
    }
`;

export const QUERY_PRODUCER_PROFILE = gql`
  query userProducer{
    userProducer {
      certificates
      delivery {
        deliveryArea
        orderDay
        stopTime
        deliveryDay
      }
      deliverySchedules
      description
      whattodo
      awards
      shortStory
      signText
      email
      id
      latitude
      location
      longitude
      province
      name
      profiles
      username
      video
      status
      isStore
      storeId
      organizationGroupIdentifier
      organizations
    }
  }
`;

export const QUERY_INIT_PRODUCER_CHECK = gql`
  query producerInitCheck{
    producerInitCheck {
      success
      message
    }
  }
`;

export const QUERY_GET_PRODUCER_BY_ID = gql`
  query Producer($id: ID!) {
    producer(id: $id) {
      name
      profiles
      description
      whattodo
      awards
      shortStory
      signText
      email
      id
      video
      profiles
      certificates
      province
      organizationGroupIdentifier
      organizations
    }
  }
`;

export const QUERY_GET_PRODUCTS_BY_IDS = gql`
  query productsBy($productFilter: ProductFilter, $saveOrder: Boolean) {
    productsBy(filter: $productFilter, saveOrder: $saveOrder) {
      data {
        id
        pictures {
          small {
            url
          }
        }
        name
        EAN
        EAN2
        unit
        consumer_size_to_order_size_ratio
        # quantityInCart @client
        quantity_in_stock
        inactive
        producer {
          id
          name
          deliverySchedules
          delivery {
            deliveryArea
            orderDay
            stopTime
            deliveryDay
          }
        }
      }
    }
  }
`;

export const QUERY_GET_PRODUCTS_FOR_UPDATE_BY_FILTER = gql`
  query productsBy($productFilter: ProductFilter, $saveOrder: Boolean) {
    productsBy(filter: $productFilter, saveOrder: $saveOrder) {
      data {
        id
        title
        pricing
        short_text
        image_src
        longitude
        latitude
        location
        creator
        created_at
        updated_at
        exportAt
        stateApprovedAt
        notifiedAt
        approvedAt
        EAN
        EAN2
        category
        foodlaCategory {
          id
          name
          coopCategoryId
          icaCategory
          coopCategory
        }
        classification
        ingredient_statement
  
        energi_kj
        energi_kcal
        fett
        mattatFett
        enkelomattatFett
        flerromattatFett
        kolhydrat
        sockerarter
        polyoler
        starkelse
        fiber
        protein
        salt
        nutritional_unit
        gross_weight_num
        gross_height
        gross_width
        gross_depth
        search_terms
  
        vitaminA
        vitaminD
        vitaminE
        vitaminK
        vitaminC
        tiamin
        riboflavin
        niacin
        vitaminB6
        folsyra
        vitaminB12
        biotin
        pantotensyra
        kalium
        klorid
        kalcium
        fosfor
        magnesium
        jarn
        zink
        koppar
        mangan
        fluorid
        selen
        krom
        molybden
        jod
  
        descriptive_size
        trade_item_temperature_information
        place_of_item_activity
        BNR
        pictureLinkName
        producer {
          id
          name
          email
          phone
          username
          description
          profiles
          latitude
          longitude
          video
          status
          isStore
          storeId
        }
        productNotes {
          __typename
          ... on ProductNoteIntArray {
            fieldName
            value
          }
        }
        video
        unit
        consumer_size_to_order_size_ratio
        adminStatus
        additionalImages
        productStatus
        alcoholPercentage
        additional_required_information
        descriptive_size_amount
        descriptive_size_amount_extra
        descriptive_size_unit
        quantity_in_stock
        inactive
        approve {
          field
          state
        }
        labels {
          field
          state
        }
  
        color
        non_food_ingredients
        material
        dimensions
        capacity
        # gross_weight
        # package_size
        # package_size_unit
        # country_of_origin
        country_of_manufacturing
        brand
        brand_food
  
        min_temperature
        max_temperature
        
        gross_weight_num
        gross_width
        gross_height
        gross_depth
        imageUnderProcess
        ignoreEANLengthError
  
        isMagazine
        price
        priceEAN
        issueActiveDate
        lastreturndate
        totalNumberIssues
        currentIssueNumber
        currentIssueYear
        alternativeTitle
  
        # detailsDescriptiveSizeOfConsumerPackAmount
        # detailsDescriptiveSizeOfConsumerPackUnit
        # detailsEffectiveDate
  
        # basicDataCategory
        basicDataMixCategory
        basicDataTradeItemVariableUnit
        basicDataTaxClassification
        basicDataPriceComparisonContentType
        basicDataAvailabilityDateFrom
        # basicDataAvailabilityDateTo
        articleFirstOrderDate
        articleFirstDelivery
        basicDataCbuIndicator
  
        # consumerPackQtyOfBaseItem
        consumerPackOrderableUnit
        consumerPackDespatchUnit
        consumerPackNetWeight
  
        # storePackUOMType
        storePackEANForStorePack
        storePackOrderableUnit
        storePackDespatchUnit
        storePackTradeItemMeasurementsLengthDepth
        storePackTradeItemMeasurementsWidth
        storePackTradeItemMeasurementsHeight
        storePackGrossWeight
  
        # transportUOMType
        transportEANForStorePack
        transportQtyOfBaseItem
        transportQtyOfNextLowerItem
        transportOrderableUnit
        transportDespatchUnit
        transportTradeItemMeasurementsLengthDepth
        transportTradeItemMeasurementsWidth
        transportTradeItemMeasurementsHeight
        transportGrossWeight
        transportMinTemperature
        transportMaxTemperature
  
        # palletLayerUOMType
        palletLayerEANForStorePack
        palletLayerQtyOfBaseItem
        palletLayerQtyOfNextLowerItem
        palletLayerOrderableUnit
        palletLayerDespatchUnit
        palletLayerTradeItemMeasurementsLengthDepth
        palletLayerTradeItemMeasurementsWidth
        palletLayerTradeItemMeasurementsHeight
        palletLayerGrossWeight
  
        # palletUOMType
        palletQtyOfStorePack
        palletEANForStorePack
        palletQtyOfBaseItem
        palletQtyOfNextLowerItem
        palletOrderableUnit
        palletDespatchUnit
        palletTradeItemMeasurementsLengthDepth
        palletTradeItemMeasurementsWidth
        palletTradeItemMeasurementsHeight
        palletGrossWeight
  
        priceListPriceCurrency
        priceListPrice
        priceRecommendedConsumerPrice
  
        purchasingDataSupplierArticleNo
        # purchasingDataSupplierSubrange
        purchasingDataIncoterm
        purchasingDataPickupLocation

        qrLabelingSwedish
        qrMedicalClaims
        qrMarketingClaimsCosmetic
        qrEfsaUlValues
        qrNutritionClaims
        qrHealthClaims
        qrFoodLabeling
        qrMisleadingSalesTerms
        qrFoodHygiene
        qrClassificationLabelingPackaging
        qrElectronic
        qrAdditivesInFeed
        qrFeedLabeling
        qrRelevantFeedCertifications

        shortSalesDescription
        animalFoodIngredients
        eggPackerApprovalNumber
        dosageAndUsageInstructions
        preparationInstructions
        responsibleLabelingApprovalNumber
        feedAdditives
        analyticalConstituentsFeed
        feedingInstructions
        animalAgeGroup
        CMRSubstances
        informationHazardousSubstances
        specifyCEStandards
        explanationNoUDI
        regulatedSubstance

        cmrSubstance
        reachSubstance
        bestBeforeDate
        childrenUnder16
        complianceEg12232009
        complianceReach19072006
        reachSubstancesAnnex19072006
        hazardousSubstancesOver1
        complianceProductSafety2004451
        ceMarking
        complianceMedicalTechProducts
        complianceHaccp
        plasticTrayPackaging
        checkUniqueIdentification

        fishProductionMethod
        fishCatchMethod
        catchArea
        eggWeightClass
        recyclingType
        recycling_amount
        signalWords
        preparedForm
        categoryWeightloss
        complianceBiocid
        complianceBiocidalTreated
        confirmGuidanceSwedishElectronics
        complianceToyDirective
        complianceMetalsSkinContact
        approvalDietFeed
        complianceSunscreenRegulation
        sunProtectionFactor

        additionalCharacteristics
        hazardSymbols
        hazardStatements
        securityNotices
        animalCategoriesForFeed
      }
    }
  }
`;

interface IFragments {
  order?: any; // TODO any
}

interface IOrders {
  fragments?: IFragments;
}

const Orders: IOrders = {};
Orders.fragments = {
  order: gql`
    fragment CommonOrderFields on Order {
      orderNumber
      producer {
        id
        name
        email
        username
        isStore
        storeId
      }
      statusInfo {
        createdAt
        updatedAt
        status
      }
      items {
        product {
          id
          name
          EAN
          EAN2
          pictures {
            small {
              url
            }
          }
          unit
          consumer_size_to_order_size_ratio
        }
        quantity
        unit
      }
    }
  `,
};

export const GET_ORDERS_QUERY = gql`
  query groupOrders($Filter: OrderFilter, $Pagination: Pagination) {
    groupOrders(filter: $Filter, pagination: $Pagination) {
      data {
        groupOrderNumber
        createdAt
        orders {
          ...CommonOrderFields
        }
      }
      pageInfo {
        total
        startCursor
        endCursor
      }
    }
  }
  ${Orders.fragments.order}
`;

export const GET_ORDERNUMBER = gql`
  query groupOrders($Filter: OrderFilter, $Pagination: Pagination) {
    groupOrders(filter: $Filter, pagination: $Pagination) {
      data {
        groupOrderNumber
        createdAt
      }
    }
  }
`;

export const GET_PRODUCER_ORDERS = gql`
  query producerOrders($Filter: OrderFilter, $Pagination: Pagination) {
    producerOrders(filter: $Filter, pagination: $Pagination) {
      data {
        groupOrderNumber
        createdAt
        ...CommonOrderFields
      }
      pageInfo {
        total
      }
    }
  }
  ${Orders.fragments.order}
`;

export const GET_PRODUCER = gql`
  query producer($id: ID!) {
    producer(id: $id) {
      id
      name
      email
      phone
      username
      description
      latitude
      longitude
      location
      province
      deliverySchedules
      profiles
      status
      isStore
      storeId
      nonLocal
      stripeCustomerId

      deliveryDescription
      whattodo
      awards
      shortStory
      signText
      organizationGroupIdentifier
      organizations
      delivery {
        deliveryArea
        orderDay
        stopTime
        deliveryDay
      }
      supplierVatNumber
      supplierGLN
      nameNordicDistributor
      website
      supplierContactPerson
      supplierContactPersonTitle
      supplierContactComplaints
      supplierPhoneComplaints
      supplierEmailComplaints
      requiredInformationComplaints
      supplierEmailOrder
      limitNoFreightFee
      minValuePerOrder
    }
  }
`;

export const GET_PRODUCER_ALL = gql`
  query producerAll($textQuery: String, $showAll: Boolean) {
    producers(textQuery: $textQuery, showAll: $showAll) {
      data {
        id
        status
        name
        username
        email
      }
    }
  }
`;

export const GET_PRODUCERS = gql`
  query producers($textQuery: String) {
    producers(textQuery: $textQuery) {
      data {
        name
        id
        profiles
        exportUrl
      }
      paging {
        next
      }
      summary {
        total
      }
    }
  }
`;

export const GET_PRODUCERS_PRODUCTS = gql`
  query producersProducts($textQuery: String, $pagePagination: PagePagination) {
    producersProducts(textQuery: $textQuery, pagePagination: $pagePagination) {
      total
      items {
        producerId
        producerName
        isStore
        productsCount
      }
    }
  }
`;

export const GET_PRODUCER_PRODUCTS_DETAIL = gql`
query producerProductsDetail($id: ID!) {
  producerProductsDetail(id: $id) {
    id
    foodlaCategory {
      id
      name
      coopCategoryId
      icaCategory
      coopCategory
    }
  }
}
`;

export const GET_PRODUCER_QUALITY_GROUP_PRODUCTS = gql`
query producerQualityGroupProducts($producerId: ID!, $qualityGroupId: String!) {
  producerQualityGroupProducts(producerId: $producerId, qualityGroupId: $qualityGroupId) {
    id
    title
    pricing
    short_text
    image_src
    longitude
    latitude
    location
    creator
    created_at
    updated_at
    exportAt
    stateApprovedAt
    notifiedAt
    approvedAt
    EAN
    EAN2
    category
    isDraft
    wasApproved
    foodlaCategory {
      id
      name
      coopCategoryId
      icaCategory
      coopCategory
    }
    classification
    ingredient_statement
    energi_kj
    energi_kcal
    fett
    mattatFett
    enkelomattatFett
    flerromattatFett
    kolhydrat
    sockerarter
    polyoler
    starkelse
    fiber
    protein
    salt
    nutritional_unit
    gross_weight_num
    gross_height
    gross_width
    gross_depth
    search_terms
    vitaminA
    vitaminD
    vitaminE
    vitaminK
    vitaminC
    tiamin
    riboflavin
    niacin
    vitaminB6
    folsyra
    vitaminB12
    biotin
    pantotensyra
    kalium
    klorid
    kalcium
    fosfor
    magnesium
    jarn
    zink
    koppar
    mangan
    fluorid
    selen
    krom
    molybden
    jod
    descriptive_size
    trade_item_temperature_information
    place_of_item_activity
    BNR
    pictureLinkName
    video
    unit
    consumer_size_to_order_size_ratio
    adminStatus
    additionalImages
    productStatus
    alcoholPercentage
    additional_required_information
    descriptive_size_amount
    descriptive_size_amount_extra
    descriptive_size_unit
    quantity_in_stock
    inactive
    approve {
      field
      state
    }
    labels {
      field
      state
    }
    color
    non_food_ingredients
    material
    dimensions
    capacity
    country_of_manufacturing
    brand
    brand_food
    min_temperature
    max_temperature
    gross_weight_num
    gross_width
    gross_height
    gross_depth
    imageUnderProcess
    ignoreEANLengthError
    isMagazine
    price
    priceEAN
    issueActiveDate
    lastreturndate
    totalNumberIssues
    currentIssueNumber
    currentIssueYear
    alternativeTitle
    basicDataMixCategory
    basicDataTradeItemVariableUnit
    basicDataTaxClassification
    basicDataPriceComparisonContentType
    basicDataAvailabilityDateFrom
    articleFirstOrderDate
    articleFirstDelivery
    basicDataCbuIndicator
    consumerPackOrderableUnit
    consumerPackDespatchUnit
    consumerPackNetWeight
    storePackEANForStorePack
    storePackOrderableUnit
    storePackDespatchUnit
    storePackTradeItemMeasurementsLengthDepth
    storePackTradeItemMeasurementsWidth
    storePackTradeItemMeasurementsHeight
    storePackGrossWeight
    transportEANForStorePack
    transportQtyOfBaseItem
    transportQtyOfNextLowerItem
    transportOrderableUnit
    transportDespatchUnit
    transportTradeItemMeasurementsLengthDepth
    transportTradeItemMeasurementsWidth
    transportTradeItemMeasurementsHeight
    transportGrossWeight
    transportMinTemperature
    transportMaxTemperature
    palletLayerEANForStorePack
    palletLayerQtyOfBaseItem
    palletLayerQtyOfNextLowerItem
    palletLayerOrderableUnit
    palletLayerDespatchUnit
    palletLayerTradeItemMeasurementsLengthDepth
    palletLayerTradeItemMeasurementsWidth
    palletLayerTradeItemMeasurementsHeight
    palletLayerGrossWeight
    palletQtyOfStorePack
    palletEANForStorePack
    palletQtyOfBaseItem
    palletQtyOfNextLowerItem
    palletOrderableUnit
    palletDespatchUnit
    palletTradeItemMeasurementsLengthDepth
    palletTradeItemMeasurementsWidth
    palletTradeItemMeasurementsHeight
    palletGrossWeight
    priceListPriceCurrency
    priceListPrice
    priceRecommendedConsumerPrice
    purchasingDataSupplierArticleNo
    purchasingDataIncoterm
    purchasingDataPickupLocation
    qrLabelingSwedish
    qrMedicalClaims
    qrMarketingClaimsCosmetic
    qrEfsaUlValues
    qrNutritionClaims
    qrHealthClaims
    qrFoodLabeling
    qrMisleadingSalesTerms
    qrFoodHygiene
    qrClassificationLabelingPackaging
    qrElectronic
    qrAdditivesInFeed
    qrFeedLabeling
    qrRelevantFeedCertifications
    shortSalesDescription
    animalFoodIngredients
    eggPackerApprovalNumber
    dosageAndUsageInstructions
    preparationInstructions
    responsibleLabelingApprovalNumber
    feedAdditives
    analyticalConstituentsFeed
    feedingInstructions
    animalAgeGroup
    CMRSubstances
    informationHazardousSubstances
    specifyCEStandards
    explanationNoUDI
    regulatedSubstance
    cmrSubstance
    reachSubstance
    bestBeforeDate
    childrenUnder16
    complianceEg12232009
    complianceReach19072006
    reachSubstancesAnnex19072006
    hazardousSubstancesOver1
    complianceProductSafety2004451
    ceMarking
    complianceMedicalTechProducts
    complianceHaccp
    plasticTrayPackaging
    checkUniqueIdentification
    fishProductionMethod
    fishCatchMethod
    catchArea
    eggWeightClass
    recyclingType
    recycling_amount
    signalWords
    preparedForm
    categoryWeightloss
    complianceBiocid
    complianceBiocidalTreated
    confirmGuidanceSwedishElectronics
    complianceToyDirective
    complianceMetalsSkinContact
    approvalDietFeed
    complianceSunscreenRegulation
    sunProtectionFactor
    additionalCharacteristics
    hazardSymbols
    hazardStatements
    securityNotices
    animalCategoriesForFeed
    bulkImportFile
  }
}
`;

export const ORDER_BY_ORDER_NUMBER = gql`
  query orderByOrderNumber($orderNumber: String!) {
    orderByOrderNumber(groupOrderNumber: $orderNumber) {
      payload {
        groupOrderNumber
        organization {
          name
        }
        logistician {
          email
        }
        orders {
          ...CommonOrderFields
        }
      }
    }
  }
  ${Orders.fragments.order}
`;

export const PRODUCER_ORDER_BY_ORDER_NUMBER = gql`
  query producerOrderByOrderNumber($orderNumber: String!) {
    producerOrderByOrderNumber(orderNumber: $orderNumber) {
      payload {
        groupOrderNumber
        organization {
          name
        }
        logistician {
          email
        }
        ...CommonOrderFields
      }
    }
  }
  ${Orders.fragments.order}
`;

export const CREATE_ORDER = gql`
  mutation createGroupOrder($orderInput: CreateGroupOrderInput!) {
    createGroupOrder(groupOrderItems: $orderInput) {
      payload {
        groupOrderNumber
        orders {
          ...CommonOrderFields
        }
      }
    }
  }
  ${Orders.fragments.order}
`;
export const CLEAR_CART = gql`
  mutation {
    clearCart @client
  }
`;
export const CLEAR_NOTES = gql`
  mutation {
    clearNotes @client
  }
`;
export const GET_SELECTED_EANS = gql`
  query {
    cartItems @client(always: true)
  }
`;

export const GET_PRODUCER_NOTES = gql`
  query {
    producerNotes @client(always: true)
  }
`;

export const MARK_ORDER_NOTIFIED = gql`
  mutation notifyOrder($OrderNumber: String!, $SendNotification: Boolean!) {
    markOrderNotified(orderNumber: $OrderNumber, sendNotification: $SendNotification) {
      payload {
        producer {
          id
        }
        statusInfo {
          status
          updatedAt
        }
      }
    }
  }
`;

// TODO parent
export const PRODUCER_TOTAL_QUERY = gql`
  query producers($isFood: Boolean, $onlyApproved: Boolean, $showAllProducers: Boolean, $filterStoresByOrg: Boolean, $showAll: Boolean, $showAllNoFilter: Boolean){
    producers {
      summary(isFood: $isFood, onlyApproved: $onlyApproved, showAllProducers: $showAllProducers, filterStoresByOrg: $filterStoresByOrg, showAll: $showAll, showAllNoFilter: $showAllNoFilter) {
        total
      }
    }
  }
`;

export const PRODUCER_TOTAL_QUERY_ADMIN = gql`
  query totalProducersAdmin($isNonFood: Boolean) {
    totalProducersAdmin(isNonFood: $isNonFood) {
      total
    }
  }
`;

export const SUPERUSER_FAILED_REVIEW_PRODUCTS = gql`
  query failedProducts($textQuery: String, $pagePagination: PagePagination) {
    failedProducts(textQuery: $textQuery, pagePagination: $pagePagination) {
      id
      title
      name
      image_src
      producer {
        name
        status
      }
      foodlaCategory {
        id
        name
        coopCategoryId
        icaCategory
        coopCategory
      }
      brand_food
      brand
    }
  }
`;

export const CHECK_UNIQ_EAN = gql`
  query($id: String!, $EAN: String, $EAN2: String, $storeId: String) {
    checkUniqEAN(id: $id, EAN: $EAN, EAN2: $EAN2, storeId: $storeId)
  }
`;

export const SUPERUSER_STATISTICS_QUERY = gql`
  query ($textQuery: String) {
    superuserProducts {
      title
    }
    superuserProducers {
      username
    }
    allProducts(textQuery: $textQuery) {
      id
      # descriptive_size_amount
      # descriptive_size_amount_extra
      # descriptive_size_unit
      title
      name
      adminStatus
    }
    superuserOrganizationsFailed {
      groupIdentifier
    }
  }
`;

export const PRODUCT_INFO_FOR_UPDATE_QUERY = gql`
  query product($id: ID!) {
    product(id: $id) {
      id
      title
      pricing
      short_text
      image_src
      longitude
      latitude
      location
      creator
      created_at
      updated_at
      exportAt
      stateApprovedAt
      notifiedAt
      approvedAt
      EAN
      EAN2
      category
      isDraft
      recognizeImages {
        url
        name
      }
      recognizedTexts {
        recognizeData
        all_text_and_digits
        image_name
        confidence_score
        certainty_ceiling
        advisory_warnings
        deductions {
          type
          reason
          severity
          points_lost
        }
      }
      aiReview {
        aiReviewIdentifiedIssues
        aiReviewRecommendations
      }
      wasApproved
      foodlaCategory {
        id
        name
        coopCategoryId
        icaCategory
        coopCategory
      }
      classification
      ingredient_statement

      energi_kj
      energi_kcal
      fett
      mattatFett
      enkelomattatFett
      flerromattatFett
      kolhydrat
      sockerarter
      polyoler
      starkelse
      fiber
      protein
      salt
      nutritional_unit
      gross_weight_num
      gross_height
      gross_width
      gross_depth
      search_terms

      vitaminA
      vitaminD
      vitaminE
      vitaminK
      vitaminC
      tiamin
      riboflavin
      niacin
      vitaminB6
      folsyra
      vitaminB12
      biotin
      pantotensyra
      kalium
      klorid
      kalcium
      fosfor
      magnesium
      jarn
      zink
      koppar
      mangan
      fluorid
      selen
      krom
      molybden
      jod

      descriptive_size
      trade_item_temperature_information
      place_of_item_activity
      BNR
      pictureLinkName
      producer {
        id
        name
        phone
        email
        username
        description
        profiles
        latitude
        longitude
        video
        status
        isStore
        storeId
        organizationGroupIdentifier
        organizations
      }
      # productNotes {
      #   __typename
      #   ... on ProductNoteIntArray {
      #     fieldName
      #     value
      #   }
      # }
      video
      unit
      consumer_size_to_order_size_ratio
      adminStatus
      additionalImages
      productStatus
      alcoholPercentage
      additional_required_information
      descriptive_size_amount
      descriptive_size_amount_extra
      descriptive_size_unit
      quantity_in_stock
      inactive
      approve {
        field
        state
      }
      labels {
        field
        state
      }

      color
      non_food_ingredients
      material
      dimensions
      capacity
      # gross_weight
      # package_size
      # package_size_unit
      # country_of_origin
      country_of_manufacturing
      brand
      brand_food

      min_temperature
      max_temperature
      
      gross_weight_num
      gross_width
      gross_height
      gross_depth
      imageUnderProcess
      ignoreEANLengthError

      isMagazine
      price
      priceEAN
      issueActiveDate
      lastreturndate
      totalNumberIssues
      currentIssueNumber
      currentIssueYear
      alternativeTitle

      # detailsDescriptiveSizeOfConsumerPackAmount
      # detailsDescriptiveSizeOfConsumerPackUnit
      # detailsEffectiveDate

      # basicDataCategory
      basicDataMixCategory
      basicDataTradeItemVariableUnit
      basicDataTaxClassification
      basicDataPriceComparisonContentType
      basicDataAvailabilityDateFrom
      articleFirstOrderDate
      articleFirstDelivery
      # basicDataAvailabilityDateTo
      basicDataCbuIndicator

      # consumerPackQtyOfBaseItem
      consumerPackOrderableUnit
      consumerPackDespatchUnit
      consumerPackNetWeight

      # storePackUOMType
      storePackEANForStorePack
      storePackOrderableUnit
      storePackDespatchUnit
      storePackTradeItemMeasurementsLengthDepth
      storePackTradeItemMeasurementsWidth
      storePackTradeItemMeasurementsHeight
      storePackGrossWeight

      # transportUOMType
      transportEANForStorePack
      transportQtyOfBaseItem
      transportQtyOfNextLowerItem
      transportOrderableUnit
      transportDespatchUnit
      transportTradeItemMeasurementsLengthDepth
      transportTradeItemMeasurementsWidth
      transportTradeItemMeasurementsHeight
      transportGrossWeight
      transportMinTemperature
      transportMaxTemperature

      # palletLayerUOMType
      palletLayerEANForStorePack
      palletLayerQtyOfBaseItem
      palletLayerQtyOfNextLowerItem
      palletLayerOrderableUnit
      palletLayerDespatchUnit
      palletLayerTradeItemMeasurementsLengthDepth
      palletLayerTradeItemMeasurementsWidth
      palletLayerTradeItemMeasurementsHeight
      palletLayerGrossWeight

      # palletUOMType
      palletQtyOfStorePack
      palletEANForStorePack
      palletQtyOfBaseItem
      palletQtyOfNextLowerItem
      palletOrderableUnit
      palletDespatchUnit
      palletTradeItemMeasurementsLengthDepth
      palletTradeItemMeasurementsWidth
      palletTradeItemMeasurementsHeight
      palletGrossWeight

      priceListPriceCurrency
      priceListPrice
      priceRecommendedConsumerPrice

      purchasingDataSupplierArticleNo
      # purchasingDataSupplierSubrange
      purchasingDataIncoterm
      purchasingDataPickupLocation

      qrLabelingSwedish
      qrMedicalClaims
      qrMarketingClaimsCosmetic
      qrEfsaUlValues
      qrNutritionClaims
      qrHealthClaims
      qrFoodLabeling
      qrMisleadingSalesTerms
      qrFoodHygiene
      qrClassificationLabelingPackaging
      qrElectronic
      qrAdditivesInFeed
      qrFeedLabeling
      qrRelevantFeedCertifications

      shortSalesDescription
      animalFoodIngredients
      eggPackerApprovalNumber
      dosageAndUsageInstructions
      preparationInstructions
      responsibleLabelingApprovalNumber
      feedAdditives
      analyticalConstituentsFeed
      feedingInstructions
      animalAgeGroup
      CMRSubstances
      informationHazardousSubstances
      specifyCEStandards
      explanationNoUDI
      regulatedSubstance

      cmrSubstance
      reachSubstance
      bestBeforeDate
      childrenUnder16
      complianceEg12232009
      complianceReach19072006
      reachSubstancesAnnex19072006
      hazardousSubstancesOver1
      complianceProductSafety2004451
      ceMarking
      complianceMedicalTechProducts
      complianceHaccp
      plasticTrayPackaging
      checkUniqueIdentification

      fishProductionMethod
      fishCatchMethod
      catchArea
      eggWeightClass
      recyclingType
      recycling_amount
      signalWords
      preparedForm
      categoryWeightloss
      complianceBiocid
      complianceBiocidalTreated
      confirmGuidanceSwedishElectronics
      complianceToyDirective
      complianceMetalsSkinContact
      approvalDietFeed
      complianceSunscreenRegulation
      sunProtectionFactor

      additionalCharacteristics
      hazardSymbols
      hazardStatements
      securityNotices
      animalCategoriesForFeed

      bulkImportFile
    }
  }
`;

//  blocks {
//     paragraphs {
//       words {
//         symbols {
//           property {
//              detectedLanguages {
//               languageCode
//                confidence
//              }
//              # detectedBreak
//            }
//            boundingBox {
//              vertices {
//                x
//                y
//             }
//              normalizedVertices {
//                x
//                y
//              }
//            }
//            text
//            confidence
//          }
//          property {
//            detectedLanguages {
//                languageCode
//                confidence
//              }
//              # detectedBreak
//          }
//          boundingBox {
//            vertices {
//              x
//              y
//            }
//            normalizedVertices {
//              x
//              y
//            }
//          }
//          confidence
//        }
//        property {
//          detectedLanguages {
//            languageCode
//            confidence
//          }
//          # detectedBreak
//        }
//        boundingBox {
//          vertices {
//            x
//            y
//          }
//          normalizedVertices {
//            x
//            y
//          }
//        }
//        confidence
//      }
//      property {
//        detectedLanguages {
//          languageCode
//          confidence
//        }
//        # detectedBreak
//      }
//      boundingBox {
//        vertices {
//          x
//          y
//        }
//        normalizedVertices {
//          x
//          y
//        }
//      }
//      blockType
//      confidence
//    }
//    property {
//      detectedLanguages {
//        languageCode
//        confidence
//      }
//      # detectedBreak
//    }
//    width
//    height
//    confidence
//  }

export const SUPERUSER_PRODUCTS_DETAILED_SUMMARY_QUERY = gql`
  query superuserProductsSummary($options: ProductsAdminApprovalOptions) {
    superuserProductsSummary(options: $options) {
      total
    }
  }
`;

// Temporary query for updating desc sice fields.
export const SUPERUSER_PRODUCTS_DETAILED_QUERY = gql`
  query superuserProducts($pagePagination: PagePagination, $options: ProductsAdminApprovalOptions) {
    superuserProducts(pagePagination: $pagePagination, options: $options) {
      id
      title
      pictureLinkName
      name
      pricing
      description
      longitude
      latitude
      location
      creator
      created_at
      exportAt
      stateApprovedAt
      notifiedAt
      approvedAt
      EAN
      EAN2
      BNR
      producer {
        id
        name
        email
        username
        isStore
        storeId
        description
        profiles
        latitude
        longitude
        video
        status
      }
      image_src
      imageUnderProcess
      ignoreEANLengthError
      video
      unit
      consumer_size_to_order_size_ratio
      adminStatus
      productStatus
      approve {
        field
        state
      }
      timestamp
      timestamp_type
      foodlaCategory {
        id
        name
        coopCategoryId
        icaCategory
        coopCategory
      }
      brand_food
      brand
    }
  }
`;

// Temporary query for updating desc sice fields.
export const TEMP_SUPERUSER_UPDATE_DESC_SIZE_FIELD = gql`
  query allProducts($textQuery: String) {
    allProducts(textQuery: $textQuery) {
      id
      title
      pictureLinkName
      name
      image_src
      descriptive_size_amount
      descriptive_size_amount_extra
      descriptive_size_unit
    }
  }
`;

export const MESSAGES_QUERY = gql`
  query messages($productId: String!) {
    messages(productId: $productId) {
      text
      created_at
      updated_at
      name
      isAdmin
      isProducer
    }
  }
`;

export const FIND_SORTED_PRODUCT_APPROVALS = gql`
  query findSortedProductApprovals($productId: String!) {
    findSortedProductApprovals(productId: $productId) {
      approvedAt
      approvedBy
      organizationGroupIdentifier
      producerId
      productId
      state
    }  
  }
`;


export const DELETED_PRODUCTS_DETAILED_QUERY = gql`
  {
    deletedProducts {
      id
      title
      name
      producer {
        id
        name
        status
      }
      image_src
      adminStatus
      productStatus
    }
  }
`;

export const SUPERUSER_PRODUCERS_NOT_APPROVED_QUERY = gql`
  query superuserProducers($textQuery: String){
    superuserProducers(textQuery: $textQuery) {
      id
      name
      username
      profiles
      isStore
      storeId
    }
  }
`;

export const ORGANIZATION_BY_ID_QUERY = gql`
  query organization($id: ID!) {
    organization(id: $id) {
      contactInformation
      deliveryAddress
      groupIdentifier
      invoiceAddress
      name
    }
  }
`;

export const ALL_PRODUCTS_QUERY = gql`
  query allProducts($textQuery: String, $pagePagination: PagePagination) {
    allProducts(textQuery: $textQuery, pagePagination: $pagePagination) {
      id
      title
      name
      EAN
      EAN2
      producer {
        id
        name
        email
        username
        isStore
        storeId
      }
      adminStatus
      productStatus
      pictures {
        medium {
          url
        }
      }
      quantity_in_stock
      inactive
      descriptive_size_amount
      descriptive_size_unit
      assortment {
        availableOnline
        comparativePriceData {
          b2cPrice
        }
        comparativePriceText
      }
    }
  }
`;

export const ALL_PRODUCTS_PAGINATION_QUERY = gql`
  query allProductsPagination($textQuery: String, $pagePagination: PagePagination, $includeDraft: Boolean, $isDraft: Boolean, $isActive: Boolean, $isInactive: Boolean) {
    allProductsPagination(textQuery: $textQuery, pagePagination: $pagePagination, includeDraft: $includeDraft, isDraft: $isDraft, isActive: $isActive, isInactive: $isInactive) {
      total
      items {
        id
        title
        name
        EAN
        EAN2
        isDraft
        recognizeImages {
          url
          name
        }
        recognizedTexts {
          recognizeData
          all_text_and_digits
          image_name
          confidence_score
          certainty_ceiling
          advisory_warnings
          deductions {
            type
            reason
            severity
            points_lost
          }
        }
        aiReview {
          aiReviewIdentifiedIssues
          aiReviewRecommendations
        }
        wasApproved
        producer {
          id
          name
          email
          username
          isStore
          storeId
        }
        adminStatus
        productStatus
        pictures {
          medium {
            url
          }
        }
        quantity_in_stock
        inactive
        descriptive_size_amount
        descriptive_size_unit
        assortment {
          availableOnline
          comparativePriceData {
            b2cPrice
          }
          comparativePriceText
        }
        foodlaCategory {
          id
          name
          coopCategoryId
          icaCategory
          coopCategory
        }
        brand_food
        brand
      }
    }
  }
`;

export const SUPERUSER_ORGANIZATIONS_QUERY = gql`
  query superuserOrganizations{
    superuserOrganizations {
      id
      name
      groupIdentifier
      parentOrg
    }
    superuserOrganizationsFailed {
      groupIdentifier
    }
  }
`;

export const ORGANIZATION_PRODUCT_UPDATE_NOTIFICATIONS_QUERY = gql`
  {
    productUpdateNotifications {
      key
      value
      date
      productId
    }
  }
`;

export const ORGANIZATION_ALL_PRODUCTS_UPDATE_NOTIFICATIONS_QUERY = gql`
  query allProductsUpdateNotifications($oneType: Boolean, $nonFood: Boolean){
    allProductsUpdateNotifications(oneType: $oneType, nonFood: $nonFood) {
      key
      value
      date
      productId
    }
  }
`;

export const QUERY_PRODUCT_SUMMARY = gql`
  query producer($id: ID!, $isFood: Boolean, $onlyApproved: Boolean) {
    producer(id: $id) {
      id
      name
      productsSummary(isFood: $isFood, onlyApproved: $onlyApproved) {
        total
        details {
          numOfPending
          numOfApproved
          numOfDisapproved
        }
      }
    }
  }
`;

export const QUERY_PRODUCER_PRODUCTS_SUMMARY = gql`
  query productsSummary($filterByProducer: Boolean, $onlyApproved: Boolean) {
    productsSummary(filterByProducer: $filterByProducer, onlyApproved: $onlyApproved) {
      total
    }
  }
`;

export const QUERY_PRODUCER_PRODUCTS = gql`
  query producer($filter: ProductFilter, $id: ID!, $pagePagination: PagePagination, $textQuery: String, $onlyApproved: Boolean, $includeDraft: Boolean) {
    producer(id: $id) {
      id
      name
      products(filter: $filter, pagePagination: $pagePagination, textQuery: $textQuery, onlyApproved: $onlyApproved, includeDraft: $includeDraft) {
        count
        edges {
          id
          name
          EAN
          EAN2
          adminStatus
          descriptive_size_amount
          descriptive_size_unit
          isDraft
          recognizeImages {
            url
            name
          }
          recognizedTexts {
            recognizeData
            all_text_and_digits
            image_name
            confidence_score
            certainty_ceiling
            advisory_warnings
            deductions {
              type
              reason
              severity
              points_lost
            }
          }
          aiReview {
            aiReviewIdentifiedIssues
            aiReviewRecommendations
          }
          wasApproved
          state {
            state
          }
          producer {
            id
            name
          }
          pictures {
            medium {
              url
            }
          }
          foodlaCategory {
            id
            name
            coopCategoryId
            icaCategory
            coopCategory
          }
          brand_food
          brand
        }
      }
    }
  }
`;

export const PRODUCER_PRODUCTS = gql`
  query userProducer($filter: ProductFilter, $textQuery: String, $onlyApproved: Boolean) {
    userProducer {
      id
      name

      productsSummary(onlyApproved: $onlyApproved) {
        details {
          numOfPending
          numOfApproved
          numOfDisapproved
        }
      }

      products(filter: $filter, textQuery: $textQuery, onlyApproved: $onlyApproved) {
        count
        edges {
          id
          name
          EAN
          EAN2
          adminStatus
          state {
            state
          }
          producer {
            id
            name
          }
          pictures {
            medium {
              url
            }
          }
        }
      }
    }
  }
`;

export const PRODUCER_OWN_PRODUCTS = gql`
  query userProducer($filter: ProductFilter, $textQuery: String, $onlyApproved: Boolean) {
    userProducer {
      id
      name
      products(filter: $filter, textQuery: $textQuery, onlyApproved: $onlyApproved) {
        count
        edges {
          id
          name
          EAN
          EAN2
          adminStatus
          state {
            state
          }
          producer {
            id
            name
          }
          pictures {
            medium {
              url
            }
          }
        }
      }
    }
  }
`;

export const NEW_PRODUCTS_FOR_EXPORT = gql`
  query exportProducts($pagePagination: PagePagination, $oneType: Boolean, $nonFood: Boolean, $onlyApproved: Boolean) {
    exportProducts(pagePagination: $pagePagination, oneType: $oneType, nonFood: $nonFood) {
      edges {
        title
        image_src
        id
        name
        EAN
        EAN2
        adminStatus
        state {
          state
        }
        producer {
          id
          name
          status
        }
        pictures {
          medium {
            url
          }
        }
        additionalImages
        descriptive_size_amount
        descriptive_size_unit
      }
      paging {
        previous
        next
      }
      count
    }
    productsSummary(onlyApproved: $onlyApproved) {
      details {
        numOfPending
        numOfApproved
        numOfDisapproved
      }
    }
  }
`;

// TODO parent
export const PRODUCT_CARD_LIST_QUERY = gql`
  query products($filter: ProductFilter, $pagePagination: PagePagination, $textQuery: String, $isFood: Boolean, $filterStoresByOrg: Boolean, $onlyApproved: Boolean, $filterStoresByParentOrg: Boolean, $includeDraft: Boolean) {
    products(filter: $filter, pagePagination: $pagePagination, textQuery: $textQuery, filterStoresByOrg: $filterStoresByOrg, onlyApproved: $onlyApproved, filterStoresByParentOrg: $filterStoresByParentOrg, includeDraft: $includeDraft) {
      edges {
        title
        image_src
        id
        name
        EAN
        EAN2
        adminStatus
        isDraft
        recognizeImages {
          url
          name
        }
        recognizedTexts {
          recognizeData
          all_text_and_digits
          image_name
          confidence_score
          certainty_ceiling
          advisory_warnings
          deductions {
            type
            reason
            severity
            points_lost
          }
        }
        aiReview {
          aiReviewIdentifiedIssues
          aiReviewRecommendations
        }
        wasApproved
        state {
          state
        }
        producer {
          id
          name
          status
        }
        pictures {
          medium {
            url
          }
        }
        additionalImages
        descriptive_size_amount
        descriptive_size_unit
        foodlaCategory {
          id
          name
          coopCategoryId
          icaCategory
          coopCategory
        }
        brand_food
        brand
      }
      paging {
        previous
        next
      }
      count
    }
    productsSummary(isFood: $isFood, filterStoresByOrg: $filterStoresByOrg, onlyApproved: $onlyApproved) {
      details {
        numOfPending
        numOfApproved
        numOfDisapproved
      }
    }
  }
`;

export const PRODUCT_CARD_LIST_QUERY_WITH_TEXT = gql`
  query products($filter: ProductFilter, $pagePagination: PagePagination, $textQuery: String, $onlyApproved: Boolean) {
    products(filter: $filter, pagePagination: $pagePagination, textQuery: $textQuery, onlyApproved: $onlyApproved) {
      edges {
        id
        name
        EAN
        EAN2
        adminStatus
        state {
          state
        }
        producer {
          id
          name
        }
        pictures {
          medium {
            url
          }
        }
        additionalImages
      }
      paging {
        previous
        next
      }
      count
    }
    productsSummary(onlyApproved: $onlyApproved) {
      details {
        numOfPending
        numOfApproved
        numOfDisapproved
      }
    }
  }
`;

export const MY_PRODUCTS_SUMMARY = gql`
  query myProductsSummary {
    myProductsSummary {
        totalCount
        activeProductsCount
        draftProductsCount
        inactiveProductsCount
    }
  }
`;

// TODO parent
export const PRODUCT_APPROVAL_SUMMARY = gql`
  query productsSummary($isFood: Boolean, $filterStoresByOrg: Boolean, $onlyApproved: Boolean) {
    productsSummary(isFood: $isFood, filterStoresByOrg: $filterStoresByOrg, onlyApproved: $onlyApproved) {
      details {
        numOfPending
        numOfApproved
        numOfDisapproved
      }
    }
  }
`;

export const EXPORT_SUMMARY = gql`
  query exportSummary{
    exportSummary {
      details {
        numOfPending
        numOfApproved
        numOfDisapproved
      }
    }
  }
`;

export const SUPERUSER_ALL_PRODUCTS_SUMMARY = gql`
  query productsSummary{
    productsSummary {
      total
    }
  }
`;

export const GET_PRODUCT_BY_ID = gql`
  query Product($id: ID!) {
    product(id: $id) {
      id
      adminStatus
      alcoholPercentage
      name
      state {
        state
      }
      producer {
        id
        name
        phone
        email
        isStore
        storeId
      }
      productNotes {
        __typename
        ... on ProductNoteIntArray {
          fieldName
          value
        }
       }
      description
      longitude
      latitude
      pricing
      video
      EAN
      EAN2
      BNR
      unit
      consumer_size_to_order_size_ratio
      category
      foodlaCategory {
        id
        name
        coopCategoryId
        icaCategory
        coopCategory
      }
      classification
      ingredient_statement
      search_terms

      energi_kj
      energi_kcal
      fett
      mattatFett
      enkelomattatFett
      flerromattatFett
      kolhydrat
      sockerarter
      polyoler
      starkelse
      fiber
      protein
      salt
      nutritional_unit
      gross_weight_num
      gross_height
      gross_width
      gross_depth

      vitaminA
      vitaminD
      vitaminE
      vitaminK
      vitaminC
      tiamin
      riboflavin
      niacin
      vitaminB6
      folsyra
      vitaminB12
      biotin
      pantotensyra
      kalium
      klorid
      kalcium
      fosfor
      magnesium
      jarn
      zink
      koppar
      mangan
      fluorid
      selen
      krom
      molybden
      jod

      descriptive_size
      descriptive_size_amount
      descriptive_size_amount_extra
      descriptive_size_unit
      trade_item_temperature_information
      place_of_item_activity
      additional_required_information
      quantity_in_stock
      inactive
      additionalImages
      pictures {
        large {
          url
        }
      }
      labels {
        field
        state
      }
      color
      non_food_ingredients
      material
      dimensions
      capacity
      # gross_weight
      # package_size
      # package_size_unit
      # country_of_origin
      country_of_manufacturing
      brand
      brand_food

      min_temperature
      max_temperature

      gross_weight_num
      gross_width
      gross_height
      gross_depth
      imageUnderProcess
      ignoreEANLengthError

      isMagazine
      price
      priceEAN
      issueActiveDate
      lastreturndate
      totalNumberIssues
      currentIssueNumber
      currentIssueYear
      alternativeTitle

      # detailsDescriptiveSizeOfConsumerPackAmount
      # detailsDescriptiveSizeOfConsumerPackUnit
      # detailsEffectiveDate

      # basicDataCategory
      basicDataMixCategory
      basicDataTradeItemVariableUnit
      basicDataTaxClassification
      basicDataPriceComparisonContentType
      basicDataAvailabilityDateFrom
      articleFirstOrderDate
      articleFirstDelivery
      # basicDataAvailabilityDateTo
      basicDataCbuIndicator

      # consumerPackQtyOfBaseItem
      consumerPackOrderableUnit
      consumerPackDespatchUnit
      consumerPackNetWeight

      # storePackUOMType
      storePackEANForStorePack
      storePackOrderableUnit
      storePackDespatchUnit
      storePackTradeItemMeasurementsLengthDepth
      storePackTradeItemMeasurementsWidth
      storePackTradeItemMeasurementsHeight
      storePackGrossWeight

      # transportUOMType
      transportEANForStorePack
      transportQtyOfBaseItem
      transportQtyOfNextLowerItem
      transportOrderableUnit
      transportDespatchUnit
      transportTradeItemMeasurementsLengthDepth
      transportTradeItemMeasurementsWidth
      transportTradeItemMeasurementsHeight
      transportGrossWeight
      transportMinTemperature
      transportMaxTemperature
      
      # palletLayerUOMType
      palletLayerEANForStorePack
      palletLayerQtyOfBaseItem
      palletLayerQtyOfNextLowerItem
      palletLayerOrderableUnit
      palletLayerDespatchUnit
      palletLayerTradeItemMeasurementsLengthDepth
      palletLayerTradeItemMeasurementsWidth
      palletLayerTradeItemMeasurementsHeight
      palletLayerGrossWeight

      # palletUOMType
      palletQtyOfStorePack
      palletEANForStorePack
      palletQtyOfBaseItem
      palletQtyOfNextLowerItem
      palletOrderableUnit
      palletDespatchUnit
      palletTradeItemMeasurementsLengthDepth
      palletTradeItemMeasurementsWidth
      palletTradeItemMeasurementsHeight
      palletGrossWeight

      priceListPriceCurrency
      priceListPrice
      priceRecommendedConsumerPrice

      purchasingDataSupplierArticleNo
      # purchasingDataSupplierSubrange
      purchasingDataIncoterm
      purchasingDataPickupLocation

      qrLabelingSwedish
      qrMedicalClaims
      qrMarketingClaimsCosmetic
      qrEfsaUlValues
      qrNutritionClaims
      qrHealthClaims
      qrFoodLabeling
      qrMisleadingSalesTerms
      qrFoodHygiene
      qrClassificationLabelingPackaging
      qrElectronic
      qrAdditivesInFeed
      qrFeedLabeling
      qrRelevantFeedCertifications

      shortSalesDescription
      animalFoodIngredients
      eggPackerApprovalNumber
      dosageAndUsageInstructions
      preparationInstructions
      responsibleLabelingApprovalNumber
      feedAdditives
      analyticalConstituentsFeed
      feedingInstructions
      animalAgeGroup
      CMRSubstances
      informationHazardousSubstances
      specifyCEStandards
      explanationNoUDI
      regulatedSubstance

      cmrSubstance
      reachSubstance
      bestBeforeDate
      childrenUnder16
      complianceEg12232009
      complianceReach19072006
      reachSubstancesAnnex19072006
      hazardousSubstancesOver1
      complianceProductSafety2004451
      ceMarking
      complianceMedicalTechProducts
      complianceHaccp
      plasticTrayPackaging
      checkUniqueIdentification

      fishProductionMethod
      fishCatchMethod
      catchArea
      eggWeightClass
      recyclingType
      recycling_amount
      signalWords
      preparedForm
      categoryWeightloss
      complianceBiocid
      complianceBiocidalTreated
      confirmGuidanceSwedishElectronics
      complianceToyDirective
      complianceMetalsSkinContact
      approvalDietFeed
      complianceSunscreenRegulation
      sunProtectionFactor

      additionalCharacteristics
      hazardSymbols
      hazardStatements
      securityNotices
      animalCategoriesForFeed
    }
  }
`;

export const PRODUCERS_ADMIN = gql`
  query producersBy($pagePagination: PagePagination, $isFood: Boolean, $filterStoresByOrg: Boolean, $onlyApproved: Boolean, $textQuery: String, $filterStoresByParentOrg: Boolean) {
    producersBy(pagePagination: $pagePagination, isFood: $isFood, filterStoresByOrg: $filterStoresByOrg, onlyApproved: $onlyApproved, textQuery: $textQuery, filterStoresByParentOrg: $filterStoresByParentOrg) {
      data {
        id
        name
        profiles
        longitude
        latitude
        productsSummary {
          details {
            numOfPending
            numOfApproved
          }
        }
      }
      producersSummary {
        total
      }
    }
  }
`;

// TODO parent
export const PRODUCER_CARD_LIST_QUERY = gql`
    query producersBy($pagePagination: PagePagination, $isFood: Boolean, $filterStoresByOrg: Boolean, $showAllProducers: Boolean, $onlyApproved: Boolean, $textQuery: String, $filterStoresByParentOrg: Boolean) {
        producersBy(pagePagination: $pagePagination, isFood: $isFood, filterStoresByOrg: $filterStoresByOrg, showAllProducers: $showAllProducers, onlyApproved: $onlyApproved, textQuery: $textQuery, filterStoresByParentOrg: $filterStoresByParentOrg) {
            data {
                name
                username
                id
                email
                longitude
                latitude
                profiles
                isStore
                storeId
                exportUrl
                productsSummary(isFood: $isFood, filterStoresByOrg: $filterStoresByOrg, onlyApproved: $onlyApproved, isIgnoreProductTotal: true) {
                    details {
                        numOfPending
                        numOfApproved
                    }
                }
            }
            paging {
                previous
                next
            }
            summary(isFood: $isFood, onlyApproved: $onlyApproved, showAllProducers: $showAllProducers, filterStoresByOrg: $filterStoresByOrg) {
                total
            }
        }
    }
`;

export const PARSE_INGREDIENTS = gql`
  query parseIngredients($text: String) {
    parseIngredients (text: $text) {
      hasItems
      items {
        EAN
        title
        category
        foodlaCategory {
          id
          name
          coopCategoryId
          icaCategory
          coopCategory
        }
        short_text
        consumer_size_to_order_size_ratio
        descriptive_size_amount
        descriptive_size_unit
        energi_kcal
        energi_kj
        enhet
        fett
        ingredient_statement
        kolhydrat
        mangd
        mattatFett
        protein
        sockerarter
        min_temperature
        max_temperature
      }
    }
  }
`;
