import React from 'react';

import {
  compose,
  join,
  map,
  replace,
  filter,
  split,
  always,
  any,
} from 'ramda';

import { APPLICATION_ROLES, ALLERGENS, ALLERGEN_EXCEPTIONS, isAllergenException } from '../../../constants';
import InputTextField from '../../../inputfields/InputTextField';
import { keyIngredients } from '../constants';
import { isOtherFoodGroup } from './category';

export const Ingredients = ({ state, setState, userRole, approve, rootCategory }) => {
  const isSuperuser = userRole === APPLICATION_ROLES.SUPERUSER;

  const [ingredientsError, setIngredientsError] = React.useState(false);
  const [ingredient, setIngredient] = React.useState(undefined);

  React.useEffect(() => {
    let e = false;
    let ing = undefined;
    const value = state?.ingredient_statement;
    if (value && value.trim !== '') {
      const regex = new RegExp(`([a-zA-ZäöåÄÖÅ0-9]*(?:${keyIngredients.join('|')})[a-zA-ZäöåÄÖÅ0-9]*)`, 'gmi');
      // const regex = new RegExp(`([a-zA-ZäöåÄÖÅ0-9]*${keyIngredients.join('|')}[a-zA-ZäöåÄÖÅ0-9]*)`, 'gmi');
      ing = regex.exec(value)?.[1];
      if (ing) {
        const regex1 = /(%|100\s*g)/;
        if (!regex1.test(value)) {
          e = true;
        }
      }
    }
    setIngredientsError(e);
    setIngredient(ing);
  }, [state, state.ingredient_statement]);

  const handleChange = event => {
    // Enforce float if descriptive_size_amount
    const stateName = event.target.name;
    let stateValue = event.target.value;
    console.log(`NAME: ${stateName} - VALUE: ${stateValue}`);
    setState({ ...state, [stateName]: stateValue });
  };
  const allergens = React.useMemo(() => {
    const processText = compose(
      list => ({ length: list.length, value: join(', ', list) }),
      map(word => `"${word}"`),
      map(replace(/"|'|,|\./g, '')),
      filter(word => word !== word.toUpperCase()),
      filter(word => {
        // First check if it's an exception
        if (isAllergenException(word)) {
          return false;
        }
        
        // Clean the word for checking against allergens
        const cleanWord = word.toLowerCase().replace(/[*]/g, '');
        
        // Then check if it contains an allergen
        return any(allergen => {
          // Simple case - word contains allergen
          if (cleanWord.includes(allergen)) {
            // For words with slashes, check each part separately
            if (cleanWord.includes('/')) {
              const parts = cleanWord.split(/[\/]/);
              // Only consider it an allergen if a complete part matches
              // or if it's not part of an exception
              return parts.some(part => 
                part === allergen || 
                (part.includes(allergen) && 
                 !ALLERGEN_EXCEPTIONS.some(exc => part.startsWith(exc.split('/')[0])))
              );
            }
            // For regular words, it's an allergen if not part of an exception
            return !ALLERGEN_EXCEPTIONS.some(exc => cleanWord.startsWith(exc));
          }
          return false;
        }, ALLERGENS);
      }),
      split(' '),
      always(state.ingredient_statement)
    );
    
    return processText();
  }, [state.ingredient_statement]); // Fixed dependency array
  
  return (
    <>
      <InputTextField
        style={{ marginTop: 7 }}
        label="Ingrediensförteckning"
        multiline
        setState={setState}
        approve={isSuperuser && approve}
        state={state}
        inputName="ingredient_statement"
        onChange={handleChange}
        multilineRows={2}
        multilineMax={20}
        value={state.ingredient_statement}
        withLabel
        inputClassName="data-hj-allow"
        labelOptions={{
          heading: 'Ingrediensförteckning',
          text:
            'Ingrediensförteckning som är angiven på förpackning. Markera allergener med VERSALER. Huvudregeln är att alla ingredienser som har använts för att tillverka livsmedlet ska finnas med i ingrediensförteckningen. De ska stå i fallande storleksordning efter den vikt de hade vid tillverkningstillfället.',
          link: 'https://kontrollwiki.livsmedelsverket.se/artikel/43/ingredienser',
          required: isOtherFoodGroup(state?.foodlaCategory, rootCategory)
        }}
      />
      {allergens.length > 0 && (
        <span style={{ fontWeight: 'bold', color: 'orangered' }}>
          Det verkar som att {allergens.value} är {allergens.length === 1 ? 'en allergen' : 'allergener'}. Om
          detta stämmer, var god skriv {allergens.length === 1 ? 'denna' : 'dessa'} i VERSALER
        </span>
      )}
      {ingredientsError && (
        <>
        {allergens.length > 0 && (<span>. </span>)}
        <span style={{ fontWeight: 'bold', color: 'orangered' }}>
        Det verkar som att "{ingredient}" behöver anges med en mängdangivelse. Om detta stämmer, var god ange mängd för "{ingredient}"
        </span>
        </>
      )}
    </>
  );
};

export default Ingredients;
