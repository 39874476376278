import { equals, find, propEq, compose, prop, map, isEmpty } from "ramda";
import { isDefined } from "../utils/helpers";
import { DESCRIPTIVE_SIZE_UNITS_MAP } from './constants-ts';

export const ORDER_STATUS_CREATED = 'CREATED';
export const ORDER_STATUS_NOTIFIED_DONE = 'NOTIFIED';
export const ORDER_STATUS_NOTIFIED_FAILURE = 'NOTIFY_FAILURE';
export const ORDER_STATUS_NOTIFIED_ERROR = 'NOTIFY_ERROR';
export const ORDER_STATUS_NOTIFIED_NOTIFYING = 'NOTIFYING';

export const ORDER_STATUS_CREATED_FRONTEND = 'Created';
export const ORDER_STATUS_DONE_FRONTEND = 'Done';
export const ORDER_STATUS_SENDING_FRONTEND = 'Sending';
export const ORDER_STATUS_ERROR_FRONTEND = 'Error';
export const ORDER_STATUS_FRONTEND = [
    ORDER_STATUS_CREATED_FRONTEND,
    ORDER_STATUS_SENDING_FRONTEND,
    ORDER_STATUS_DONE_FRONTEND,
];

export const SUPERUSER_APPROVAL = {
    ADMIN_APPROVED: "ADMIN_APPROVED",
    AWAITING_APPROVAL: "AWAITING_APPROVAL",
    ADMIN_DISAPPROVED: "ADMIN_DISAPPROVED",
  };

  export const PRODUCT_STATUS = {
    MARKED_FOR_REMOVAL: 'MARKED_FOR_REMOVAL',
    DELETED: 'DELETED',
  };

export const APPLICATION_ROLES = {
    PRODUCER: 'producer',
    LOGISTICIAN: 'logistician',
    ADMIN: 'admin',
    SUPERUSER: 'superuser',
    UNAUTHORIZED: 'unauthorized',
    QUALITY: 'quality',
    STORE: 'store',
};

export const NUTRITIONAL_UNITS_MAP = [
  { enum: 'GRAM', value: 'g'},
  { enum: 'GRAM', value: 'gr'},
  { enum: 'GRAM', value: 'gram'},
  { enum: 'MILLILITER', value: 'ml'},
  { enum: 'MILLILITER', value: 'milliliter'},
];

export const getDescriptiveSizeEnumFromValue = (value) => {
  const eV = compose(prop('enum'), find(propEq('value', value)))(DESCRIPTIVE_SIZE_UNITS_MAP)
  return isDefined(eV) ? eV : '';
}

export const getDescriptiveSizeValueFromEnum = (enumType) => {
  const v = compose(prop('value'), find(propEq('enum', enumType)))(DESCRIPTIVE_SIZE_UNITS_MAP)
  return isDefined(v) ? v : '';
}

export const getNutritionalEnumFromValue = (value) => {
  const eV = compose(prop('enum'), find(propEq('value', value)))(NUTRITIONAL_UNITS_MAP)
  return isDefined(eV) ? eV : '';
}

export const getNutritionalValueFromEnum = (enumType) => {
  const v = compose(prop('value'), find(propEq('enum', enumType)))(NUTRITIONAL_UNITS_MAP)
  return isDefined(v) ? v : '';
}

const PRODUCT_NOTE_INPUT_TYPES = {
  INT_ARRAY: 'intArrayValue'
}

const intArrayReducer = (ack, key) => ack.concat(`${key},`);
export const PRODUCT_NOTE_TYPES_EXTRACTOR = {
  'ProductNoteIntArray': (value) => {
    if(isEmpty(value)) return value;
    return value.reduce(intArrayReducer, '').slice(0, -1);
  }
}

// TODO: Implemnt failure handling.
// TODO: Go over this
export const PRODUCT_NOTES_TYPE_TRANSFORMERS = {
  [PRODUCT_NOTE_INPUT_TYPES.INT_ARRAY]: (valueToTransform) => {
    if (valueToTransform) {
      return map(v => parseInt(v))(valueToTransform.split(','));
    } else {
      return [];
    }
  }
};

export const ORGANIZATION_SPECIFIC_FIELDS = {
  COOP: [
    {
      fieldName: 'ItemCategoriesOnline',
      inputType: PRODUCT_NOTE_INPUT_TYPES.INT_ARRAY,
      formLabel: 'Item Categories Online',
      info: 'Accepts a list of numbers, each entry should be separated by a comma.',
      transformer: PRODUCT_NOTES_TYPE_TRANSFORMERS[PRODUCT_NOTE_INPUT_TYPES.INT_ARRAY]
    }
  ]
};

// Keep this lowercased
export const ALLERGENS = [
  'abborre', 'aborr', 'alaska pollock', 'ansjovis', 'ariopsis felis', 'atlantlax', 'bergtung', 'bergtunga',
  'blå gurami', 'bläckfisk', 'blötdjur ', 'bonito', 'bredrandig ansjovis', 'cashewnöt',  'dinkel', 'drakfisk',
  'filmjölk', 'fisk', 'fjärsing', 'fläckig havskatt', 'fugu', 'gråsej', 'grädde', 'gräddfil', 'guldsparid', 'gädd',
  'gädda', 'gös', 'hajmal', 'harr', 'hasselnöt', 'havre', 'havsabborre', 'havskatt', 'hoki', 'hundhaj', 'hälleflund',
  'hälleflundra', 'jordnöt', 'kamut', 'kapkummel', 'karp', 'kedjepickerell', 'khorasanvete', 'kolja', 'korn', 'kräft',
  'kummel', 'lake', 'laktos', 'lax', 'litopenaeus vannamei', 'lupin', 'lyrtorsk', 'makadamianöt', 'makrill', 'malt',
  'mandel', 'mangrovesnapper', 'marulk', 'mjölk', 'mulle', 'mussla', 'musslor', 'mört', 'nors', 'ost', 'paranöt',
  'pekannöt', 'pigghaj', 'piggvar', 'pistasch', 'pistaschmandel',  'queenslandsnöt', 'regnbåg', 'regnbåge', 'ruda',
  'råg', 'räk', 'röd snapper', 'rödspätt', 'rödspätta', 'rödtunga', 'sandskädd', 'sandskädda', 'sardin', 'sej', 'selleri',
  'senap', 'sesam', 'sesamfrö', 'sik', 'siklöja', 'sill', 'sjurygg', 'sjötunga', 'skarpsill', 'skrubbskädda', 'slätvar',
  'smör', 'snigel', 'snäck', 'soja', 'spelt', 'strömming', 'sulfit', 'sutare', 'svaveldioxid', 'svärdfisk', 'tilapia',
  'tjockläppad multe', 'tonfisk', 'torsk', 'valnöt', 'vete', 'vitling', 'yoghurt', ' ål', 'ägg', 'öring'
];

export const ALLERGEN_EXCEPTIONS = [
  'kakaosmör', 
  'kokosmjölkspulver',
  'kokosmjölk',
  // Add variations with slashes
  'kakaosmör/smør',
  'kokosmjölks/mælk/melkpulver'
];

// Function to check if a word is in the exception list or contains an exception
export const isAllergenException = (word) => {
  // Remove special characters like asterisks
  const cleanWord = word.toLowerCase().replace(/[*]/g, '');
  
  // Direct match with any exception
  if (ALLERGEN_EXCEPTIONS.some(exception => cleanWord === exception)) {
    return true;
  }
  
  // Check for exceptions at the beginning of the word
  if (ALLERGEN_EXCEPTIONS.some(exception => cleanWord.startsWith(exception))) {
    return true;
  }
  
  // Check for slash variations
  if (cleanWord.includes('/')) {
    // Check if any part of a slash-separated word matches an exception
    const parts = cleanWord.split(/[\/]/);
    
    // Check if any of the base exceptions match the start of any part
    return ALLERGEN_EXCEPTIONS.some(exception => {
      const exceptionBase = exception.split('/')[0];
      return parts.some(part => part.startsWith(exceptionBase));
    });
  }
  
  return false;
};


export const PRODUCT_CLASSIFICATIONS= ['KLASS ”EXTRA”', 'KLASS 1', 'KLASS 2'];

export const MAIN_LABELS = [
  { title: 'Ekologiskt', field: 'ecological' },
  { title: 'Laktosfri', field: 'lactoseFree' },
  { title: 'Glutenfri, Det överkorsade axet', field: 'glutenFree' },
  { title: 'Veganskt', field: 'vegan' },
  { title: 'I\'m Vegan', field: 'iAmVegan'},
  { title: 'Vegetariskt', field: 'vegetarian' },
  { title: 'Nyckelhål', field: 'nyckelhal' },
  { title: 'Äggfri', field: 'aggfri' },
  { title: 'Låg sockerhalt', field: 'lowSugar' },
  { title: 'Hög fiberhalt', field: 'hogFiberhalt' },
  { title: 'Låg fetthalt', field: 'lowFat' },
  { title: 'Från Sverige', field: 'fromSweden' },
  { title: 'Fairtrade', field: 'fairtrade' },
  { title: 'Svenskt sigill', field: 'svensktSigill' },
  { title: 'Svenskt sigill klimatcertifierad', field: 'svensktSigillKlimatcertifierad' },
  { title: 'Svenskt sigill naturbeteskött', field: 'svensktSigillNaturbeteskott' },
  { title: 'Kött från Sverige', field: 'kottFranSverige' },
  { title: 'Mjölk från Sverige', field: 'milkSweden' },
  { title: 'Svensk fågel', field: 'svenskFagel' },
  { title: 'Äkta Vara', field: 'aktaVara' },
  { title: 'Fri från mjölkprotein', field: 'friFranMjolkprotein' },
  { title: 'ASC', field: 'ASC' },
  { title: 'MSC', field: 'MSC' },
  { title: 'FSC Forest Steward Council 100 percent', field: 'FSC' },
  { title: 'FSC Forest Stewardship Council', field: 'FSCStewardshipCouncil' },
  { title: 'FSC Forest Steward Council Mix', field: 'FSCStewardshipCouncilMix' },
  { title: 'FSC Forest Steward Council Recycled', field: 'FSCStewardshipCouncilRecycled' },
  { title: 'Rainforest alliance', field: 'rainforestAlliance' },
  { title: 'Hög Koffeinhalt', field: 'highCoffein' },
  { title: 'Global Organic Textile Standard', field: 'globalOrganicTextileStandard' },
  { title: 'Rekommenderas av Astma och Allergiförbundet', field: 'approvedByAsthmaAndAllergyAssoc' },
  { title: 'Fairtrade Cotton', field: 'fairtradeCotton' },
  { title: 'Fairtrade Cacao', field: 'fairtradeCacao' },
  { title: 'Fairtrade Sugar', field: 'fairtradeSugar' },
];

// EKO-Märkingar
export const ECO_LABELS = [
  { title: 'Australian Certified Organic', field: 'australianOrganic' },
  { title: 'BDIH', field: 'BDIH' },
  { title: 'Ecocert Cosmos Natural', field: 'ecocertCosmosNatural' },
  { title: 'Ecocert Cosmos Organic', field: 'ecocertCosmosOrganic' },
  { title: 'EcoControl (COSMOS)', field: 'ecoControl' },
  { title: 'EU-lövet', field: 'EULeaf' },
  { title: 'Krav', field: 'kravmarkt' },
  { title: 'Natrue', field: 'nature' },
  { title: 'NSF', field: 'NSF' },
  { title: 'Oasis', field: 'oasis' },
  { title: 'Oeko-Tex', field: 'oekoTex' },
  { title: 'OEKO-Tex Made in Green', field: 'oekoTexGreen' },
  { title: 'Soil Association', field: 'soliAssociation' },
  { title: 'USDA', field: 'USDA' },
  { title: 'ECOCERT', field: 'ecoCert' },
  { title: 'Annan EKO märkning/ certifiering', field: 'otherLabelingECO' },
];

export const VEGAN_LABELS = [
  { title: 'Vegan Trademark', field: 'veganTrademark' },
  { title: 'Certified Vegan', field: 'certifiedVegan' },
  { title: 'Crueltyfree and Vegan', field: 'crueltyFreeVegan' },
  { title: 'V-label vegan', field: 'vLabelVegan' },
  { title: 'Vegan Society', field: 'veganSociety' },
  { title: 'Annan vegansk märkning/ certifiering', field: 'otherLabelingVegan' },
];

export const ENVIRONMENTAL_LABELS = [
  { title: 'Bra Miljöval', field: 'braMiljoval' },
  { title: 'Svanen', field: 'svanen' },
  { title: 'EU Ecolabel (Eu-blomman)', field: 'EUEcolabel' },
  { title: 'Annan miljö märkning/ certifiering', field: 'otherLabelingEnvironment' }
];

export const RECYCLING_AMOUNT = [
  { displayName: '1 SEK', value: 1 },
  { displayName: '2 SEK', value: 2 },
];

export const getPercentageValues = () => {
    const values = new Array(35).fill(0.1);
    for (let i = 1; i < 36; i++) {
        values[i - 1] = (values[i - 1] * i).toFixed(1);
    }
    values.splice(0, 0, 0.0);
    return values;
};
