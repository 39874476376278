import isNil from 'lodash/isNil';

import IProduct from 'models/product';
import { checkEANValue } from 'components/product/ProductUpdateForm';
import { isBlank } from 'utils/helpers-ts';
import { IReviewColumn, TValidatorResult } from '../type';

// **********************************************
// Validators for Special fields
// **********************************************
export const eanValidator = (product: IProduct): TValidatorResult => {
  const { eanLengthError, eanControlError, eanWrongCoopStoreError, nonUniqError, eanNotOnlyDegit } = checkEANValue(
    product,
    true
  );

  const errorMessages: string[] = [];

  if (isBlank(product.EAN)) errorMessages.push('Vänligen fyll i detta fält.');
  if (eanLengthError) errorMessages.push(eanLengthError);
  if (eanControlError) errorMessages.push(eanControlError);
  if (eanWrongCoopStoreError) errorMessages.push(eanWrongCoopStoreError);
  if (nonUniqError) errorMessages.push(nonUniqError);
  if (eanNotOnlyDegit) errorMessages.push('Only digits allowed');

  return { errorMessages };
};

// **********************************************
// Validators for All fields
// **********************************************
export const requiredFieldValidator = (product: IProduct, column: IReviewColumn): TValidatorResult => {
  const value = product[column.field];

  const errorMessages: string[] = [];

  if (column.type === 'text' && isBlank(value)) {
    errorMessages.push('Missing value');
  } else if (isNil(value)) {
    errorMessages.push('Missing value');
  }

  return { errorMessages };
};
